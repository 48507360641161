//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState } from 'vuex';
 
export default {
props: {
    content: {
        type: Object,
        default: ()=>{}
    },
    truncate:  {
      type: Boolean,
      default: false
    },
  
},
 computed: {
      ...mapState([
          'location','page',
      ]),
      //  ...mapGetters([
      // 'productList',
      //  ]),
      //  product(){
      //    const id = this.content.link.cached_url.split('id=')[1];   
      //    return this.productList(269603)
      //  }
  }, 
 methods: {
      scrubLink(link) {
        return link.replace('pages', '')
      },
      async routeLink(e, url) {
        if(url == '/menu' || url == 'menu') {
          e.preventDefault()
          if(!this.location || this.location.id != this.page.content.id) {
            this.$store.commit('setLoading', true)
            let location = await this.$api.getLocation(this.page.content.store_num)
            this.$store.commit('setLocation', location)
            let menu_id = location.menu_id
            if(!menu_id) {
              menu_id = process.env.DEFAULT_MENU
            }
            let menu = await this.$api.getMenu(menu_id)
            this.$store.commit('setLoading', false)
            if(menu) {
              this.$store.commit('setActiveMenu', menu)
            } else {
              // this.$store.commit('setActiveErrors', ['Connection issue. Please try again'])
            }
          }
          this.$router.push('/menu')
        }
      }
    }

}
