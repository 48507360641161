//
//
//
//
//
//
//
//
//

import AppLink from './AppLink'
import Card from './Card'

export default {
    components: {
      AppLink,
    },
    props: [
      'content'
    ],  
    methods: {
      scrubLink(link) {
        return link.replace('pages/', '')
      },
      matchHeight() {
        setTimeout(function() {      
          let sliders = document.querySelectorAll('.callout-cards .card')
          let greatestHeight = 0
          for (var i = sliders.length - 1; i >= 0; i--) {
            let cards = sliders[i].querySelectorAll('.card-body p')
            let lastHeight = null
            for (var i2 = cards.length - 1; i2 >= 0; i2--) {
              cards[i2].style.height = 'auto'
            }
            for (var i2 = cards.length - 1; i2 >= 0; i2--) {
              lastHeight = cards[i2].offsetHeight
              if (lastHeight >= greatestHeight) {
                greatestHeight = lastHeight
              }
            }
            for (var i2 = cards.length - 1; i2 >= 0; i2--) {
              cards[i2].style.height = greatestHeight+'px'
            }
          }
        }.bind(this), 100)
      }
    },
    mounted() {
      window.addEventListener('resize', this.matchHeight)
      this.matchHeight()
    },
    destroyed() {
      window.removeEventListener('resize', this.matchHeight)
    }
  }
