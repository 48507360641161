//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import AppLink from './AppLink'

export default {
    computed: {
        loyalty() {
            let loc = this.$store.state.location
            if (loc) {
                if (loc.loyalty === true) {
                    return true
                } else {
                    return false
                }
            } else {
                return false
            }
        },
    },
    components: {
        AppLink,
    },
    props: {
        content: {
            type: Object,
            default: () => ({
                heading: 'Stay in the Know',
                text: 'Subscribe to our eClub and get 20% off your next order! Offer arrives within 24 hours*.',
                cta: [{ 'url': { 'url': 'http://prkmc.fbmta.com/members/UpdateProfile.aspx?Action=Subscribe&_Theme=45097156841&InputSource=Website' }, title: 'Sign up', linktype: 'url' }],
            })
        }
    },
    methods: {
        scrubLink(link) {
            return link.replace('pages/', '')
        },
    }
}
