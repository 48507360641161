//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

   
export default {
 
  props: [
    'content'
  ],
  methods: {
    scrubLink(link) {
      return link.replace('pages/', '')
    },
    showNext() {
      this.$refs.slider.next()
    },
    matchHeight() {
      setTimeout(function() {      
        let sliders = document.querySelectorAll('.menu-slider')
        for (var i = sliders.length - 1; i >= 0; i--) {
          let cards = sliders[i].querySelectorAll('.card-body')
          let greatestHeight = 0
          let lastHeight = null
          for (var i2 = cards.length - 1; i2 >= 0; i2--) {
            cards[i2].style.height = 'auto'
          }
          for (var i2 = cards.length - 1; i2 >= 0; i2--) {
            lastHeight = cards[i2].offsetHeight
            if (lastHeight > greatestHeight) {
              greatestHeight = cards[i2].offsetHeight
            }
          }
          for (var i2 = cards.length - 1; i2 >= 0; i2--) {
            cards[i2].style.height = greatestHeight+'px'
          }
        }
      }, 100)
    }
  },
  mounted() {
    window.addEventListener('resize', this.matchHeight)
    this.matchHeight()
  },
  destroyed() {
    window.removeEventListener('resize', this.matchHeight)
  }
}
