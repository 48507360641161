//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import AppLink from './AppLink'

export default {
    components: {
      AppLink,
    },
    props: [
      'content',
      'truncate',
    ],  
    methods: {
      scrubLink(link) {
        return link.replace('pages', '')
      },
      async routeLink(e, url) {
        if(url == '/menu' || url == 'menu') {
          e.preventDefault()
          if(!this.$store.state.location || this.$store.state.location.id != this.$store.state.page.content.id) {
            this.$store.commit('setLoading', true)
            let location = await this.$api.getLocation(this.$store.state.page.content.store_num)
            this.$store.commit('setLocation', location)
            let menu_id = location.menu_id
            if(!menu_id) {
              menu_id = process.env.DEFAULT_MENU
              // this.$store.state.defaultMenus.map(m => {
              //   if(m.locs.find(l => l.toString() == location.id.toString())) {
              //     menu_id = m.menu_id
              //   }
              // })
            }
            let menu = await this.$api.getMenu(menu_id)
            this.$store.commit('setLoading', false)
            if(menu) {
              this.$store.commit('setActiveMenu', menu)
            } else {
              // this.$store.commit('setActiveErrors', ['Connection issue. Please try again'])
            }
          }
          this.$router.push('/menu')
        }
      }
    }
  }
