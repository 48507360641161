//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Locations from '~/components/common/Locations'
import MenuSlider from '~/components/menu/MenuSlider'
import MenuCarousel from '~/components/menu/MenuCarousel'
import MenuHero from '~/components/menu/MenuHero'
import ThreeSteps from '~/components/common/ThreeSteps'
import CalloutCards from '~/components/common/CalloutCards'
import Subscribe from '~/components/common/Subscribe'

export default {
  components: {
    Locations,
    MenuSlider,
    MenuHero,
    Subscribe,
  },
  props: [
    'content',
  ],
  data() {
    return {
      components: {
        'three-steps': ThreeSteps,
        'menu-slider': MenuSlider,
        'menu-carousel': MenuCarousel,
        'callout-cards': CalloutCards,
        'subscribe': Subscribe,
      },
      showAllSubcategories: false,
      ready: false,
      activeCategory: '',
      activeSubcategory:'',
      menuHeight: 391,
      showInfo: false,
      scrollable: true,
      slider: {
        "cta": [
        {
          "url": {
            "url": "",
            "linktype": "story",
            "fieldtype": "multilink",
            "cached_url": "pages/legal"
          },
          "title": "Example",
        }
        ],
        "size": "lg",
        "cards": [
        {
          "cta": "$0.00",
          "link": {
            "url": "",
            "linktype": "story",
            "fieldtype": "multilink",
            "cached_url": "pages/legal"
          },
          "text": "Text",
          "image": {
            "alt": "",
            "filename": "https://a.storyblok.com/f/99717/x/303939b460/perkins-default-icon-sm.svg",
          },
          "heading_2": "Heading",
        },
        {
          "cta": "$0.00",
          "link": {
            "url": "",
            "linktype": "story",
            "fieldtype": "multilink",
            "cached_url": "pages/legal"
          },
          "text": "Text",
          "image": {
            "alt": "",
            "filename": "https://a.storyblok.com/f/99717/x/303939b460/perkins-default-icon-sm.svg",
          },
          "heading_2": "Heading",
        },
        {
          "cta": "$0.00",
          "link": {
            "url": "",
            "linktype": "story",
            "fieldtype": "multilink",
            "cached_url": "pages/legal"
          },
          "text": "Text",
          "image": {
            "alt": "",
            "filename": "https://a.storyblok.com/f/99717/x/303939b460/perkins-default-icon-sm.svg",
          },
          "heading_2": "Heading",
        },
        {
          "cta": "$0.00",
          "link": {
            "url": "",
            "linktype": "story",
            "cached_url": "pages/legal"
          },
          "text": "Text",
          "image": {
            "alt": "",
            "filename": "https://a.storyblok.com/f/99717/x/303939b460/perkins-default-icon-sm.svg",
          },
          "heading_2": "Heading",
        }],
        "heading": "Fresh Farm Omelettes",
        "arrows": true
      },
    }
  },
  async asyncData({ $storyblok, store }) {
    const page = await $storyblok.getPage('pages/order')
    store.commit('setPage', page)
    // return {
    //   page: page,
    // }
  },
  head() {
    return this.$metadata(this.page)
  },
  updated() {
    this.matchHeight()
  },
  mounted: function() {
    window.addEventListener('scroll', this.scroll)
    window.addEventListener('resize', this.matchHeight)
    this.matchHeight()

    if(this.menu) {      
      this.ready = true
      let activeCategory = null
      if(this.menu.supercategories) {
        this.menu.supercategories.map((c, i) => {
          if(this.hasSubCats(c) && activeCategory == null) {
            activeCategory = i
          }
        })
      }
      this.setActiveCategory(activeCategory)
      
    }

    if (this.$route.hash) { // set category based on hash value
        let subCategories = []
        let flag = false
        // console.log('this.$route.hash:::::::: ', this.$route.hash)
        for (let i = this.menu.supercategories.length - 1; i >= 0; i--) {
            let slug = '#' + this.menu.supercategories[i].name.replace(/'/g, '').replace(/[^a-zA-Z]+/g, "_").replace(/_+/g, '_').replace(/\_$/, '').toLowerCase()
            // subCategories.push(this.menu.supercategories[i].subcategories)
            subCategories = [...subCategories, ...this.menu.supercategories[i].subcategories]
            // console.log('slug::::::::::::::: ', slug)
            if (this.$route.hash == slug) {
                // console.log('slug::::::::::::::: ', slug)
                this.setActiveCategory(i)    
                flag = true            
                break                
            } 
            
        }

        if (!flag) {
            // console.log('no categories found, looking for subcategories')
            for (let i = this.menu.supercategories.length - 1; i >= 0; i--) {
                let subcat_slug = null
                for (let j = 0; j < this.menu.supercategories[i].subcategories.length; j++) {
                    subcat_slug = this.menu.supercategories[i].subcategories[j].name.trim().replace(/'/g, '').replace(/[^a-zA-Z]+/g, "_").replace(/_+/g, '_').replace(/\_$/, '').toLowerCase()
                    
                    if (subcat_slug.charAt(0) === '_') {
                        subcat_slug = subcat_slug.substring(1)
                    }
                    
                    if (this.$route.hash.split("#")[1] == subcat_slug) {
                        // console.log('this.menu.supercategories[i] ', this.menu.supercategories[i])
                        // console.log('this.menu.supercategories[i].subcategories[j] ', this.menu.supercategories[i].subcategories[j])
                        // console.log('this.menu.supercategories[i].name::::::::: ', this.menu.supercategories[i].name)
                        // console.log('this.$route.hash.split("#")[1]  ', this.$route.hash.split("#")[1])
                        // console.log('subcat_slug::: ', subcat_slug)
                        this.setActiveCategory(i)
                        // console.log('this.categories', this.categories)
                        let subcat_slug2 = null
                        for (let x = 0; x < this.categories.length; x++) {
                            subcat_slug2 = this.categories[x].name.trim().replace(/'/g, '').replace(/[^a-zA-Z]+/g, "_").replace(/_+/g, '_').replace(/\_$/, '').toLowerCase()
                            
                            if (subcat_slug2.charAt(0) === '_') {
                                subcat_slug2 = subcat_slug2.substring(1)
                            }
                            if (this.$route.hash.split("#")[1] == subcat_slug2) {
                                this.setActiveSubcategory(x)
                                break
                            }
                        } 
                        
                        break
                    }
                } 
                
            }
        }
    }
    if (this.$route.query) {
        if (!this.$nuxt.context.from || (this.$nuxt.context.from && !this.$nuxt.context.from.path.startsWith('/order/product') && !this.$nuxt.context.from.path.startsWith('/menu/product'))) { // doesn't set category based on query params if coming from product page
            if (this.$route.query.category) {
                
                // Set active category placed on category query param
                let cat_slug = null
                for (let i = 0; i < this.menu.supercategories.length; i++) {
                    cat_slug = this.menu.supercategories[i].name.replace(/'/g, '').replace(/[^a-zA-Z]+/g, "_").replace(/_+/g, '_').replace(/\_$/, '').toLowerCase()
                    if (cat_slug.charAt(0) === '_') {
                        cat_slug = cat_slug.substring(1)
                    }
                    if (this.$route.query.category == cat_slug) {
                        this.setActiveCategory(i)
                    }

                        
                }
            }
            if (this.$route.query.subcategory) {
                // console.log('this.$route.query.subcategory:::::::::: ', this.$route.query.subcategory)
                // Get subcategories for the active super category
                let subCategories = []
                let cat_slug = null
                for (let i = 0; i < this.menu.supercategories.length; i++) {
                    cat_slug = this.menu.supercategories[i].name.replace(/'/g, '').replace(/[^a-zA-Z]+/g, "_").replace(/_+/g, '_').replace(/\_$/, '').toLowerCase()
                    if (cat_slug.charAt(0) === '_') {
                        cat_slug = cat_slug.substring(1)
                    }
                    if (this.$route.query.category == cat_slug) {
                        subCategories.push(this.menu.supercategories[i].subcategories)
                    }

                        
                }
                // console.log('subCategories::::::::: ', subCategories)
                // console.log('this.categories:::::::::: ', this.categories)
                // Check each subcategory and match it to query param
                let subcat_slug = null
                for (let i = 0; i < this.categories.length; i++) {
                    subcat_slug = this.categories[i].name.trim().replace(/'/g, '').replace(/[^a-zA-Z]+/g, "_").replace(/_+/g, '_').replace(/\_$/, '').toLowerCase()
                    
                    if (subcat_slug.charAt(0) === '_') {
                        subcat_slug = subcat_slug.substring(1)
                    }
                    if (this.$route.query.subcategory == subcat_slug) {
                        this.setActiveSubcategory(i)
                    }
                }                
            }
        }
    }

    // old hash functionality
    // setTimeout(function() { 
    //   this.hashSetActiveCategory()
    // }.bind(this), 100)
    // window.addEventListener('hashchange', this.hashSetActiveCategory)
  },
  destroyed () {
    window.removeEventListener('scroll', this.scroll)
  },
  watch: {
    '$route.path': function(e) {
      this.matchHeight()
    }
  },
  methods: {
    scroll() {
      if (process.browser) {
        try {
          let nav = document.querySelector('.supercategories')
          let menu = document.querySelector('.main')
          const bg = document.querySelector(".menu")
          try {
            if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)){
              if (bg.getBoundingClientRect().y <= -460) {
                nav.classList.add("fixed");
                menu.style.padding = "300px 40px 0 40px"
              } else {
                nav.classList.remove("fixed");
                menu.style.padding = "0 40px"
              }
            } else {
              if (bg.getBoundingClientRect().y <= -500) {
                nav.classList.add("fixed");
                menu.style.padding = "300px 40px 0 40px"
              } else {
                nav.classList.remove("fixed");
                menu.style.padding = "0 40px"
              }
            }
          } catch(err) {
            nav.classList.remove("fixed")
          }
        } catch(err) {
          // dom may not be loaded
        }
      }
    },
    hashSetActiveCategory() {
      if (location.hash == '#favorites') {
        this.setActiveCategory(0)
      }
      if (location.hash == '#breakfast') {
        this.setActiveCategory(1)
      }
      if (location.hash == '#lunch-and-dinner') {
        this.setActiveCategory(2)
      }
      if (location.hash == '#bakery') {
        this.setActiveCategory(3)
      }
      if (location.hash == '#kids') {
        this.setActiveCategory(4)
      }
      if (location.hash == '#beverages') {
        this.setActiveCategory(5)
      }
      if (location.hash !== '') {
        document.querySelector('.categories').scrollIntoView()
      }
    },
    hasSubCats(sup) {
      let found = 0;
      if(sup.subcategories) {
        sup.subcategories.map(s => {
          this.menu.categories.map(c => {
            if(s.id.indexOf(c.id) > -1) {
              found++
            }
          })
        })
      }
      return found > 0
    },
    getComponent(type) {
      if(this.components[type]) {
        return this.components[type]
      }
    },
    matchHeight() {
     setTimeout(function () {
      if (this.$refs.menuHeader) {
        let height = this.$refs.menuHeader.clientHeight
        this.menuHeight = height
      }
     }.bind(this), 100)
    },
    changeLocation() {
      this.$store.commit('setLocationSelector', true)
    },
    setActiveSubcategory(i) {
      this.showAllSubcategories = false
      if (i == '') {
        i = 0
      }
      this.slider.cards = []
      this.activeSubcategory = i
      if(this.categories && this.categories.length){
           this.slider.heading = this.categories[i].name
            this.categories[i].products.forEach((product) => {
              let card = {
                "cta": 'View',
                "link": {
                  "url": "",
                  "linktype": "story",
                  "fieldtype": "multilink",
                  "cached_url": '/order/product?cat='+this.categories[i].id+'&id='+product.sku
                },
                "text": product.description,
                "image": {
                  "alt": "",
                  "filename": product.images ? this.menu.imagepath + product.images[product.images.length - 1].filename : "https://a.storyblok.com/f/99717/x/303939b460/perkins-default-icon-sm.svg",
                },
                "heading_2": product.name,
              }

              this.slider.cards.push(card)
            })
 
      }
     
      setTimeout(function() {
        window.dispatchEvent(new Event('resize'))
      }.bind(this), 100)
    },
    setActiveCategory(i) {
      this.showAllSubcategories = false
      this.activeCategory = i
      this.setActiveSubcategory('')
      setTimeout(function() {
        window.dispatchEvent(new Event('resize'))
      }.bind(this), 100)
    }
  },
  computed: {
    page() {
      return this.$store.state.page
    },
    loading() {
      return this.$store.state.loading
    },
    loc() {
      return this.$store.state.location
    },
    menu() {
      return this.$store.state.activeMenu
    },
    supercat() {
      return this.menu ? this.menu.supercategories[this.activeCategory] : null
    },
    categories() {
      let cats = []
     this.supercat && this.menu && this.menu.categories ?  this.supercat.subcategories.map(s => {
        this.menu.categories.map(c => {
          if(s.id.indexOf(c.id) > -1) {
            cats.push(c)
          }
        })
      }) : []
      return cats
    },
    catlistClass(){
      const catlength =  this.categories ? this.categories.length: 0 
       switch (catlength) {
         case 1 :
          return 'max-w-sm' ; 
         case 2 :
          return 'max-w-lg' ;  
         case 3 :
          return 'max-w-2xl' ;  
         case 4 :
          return 'max-w-4xl' ;  
         case 5 :
          return 'max-w-5xl' ; 
         default:
           break;
       } 
    }
  },
}
