//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import moment from 'moment'
export default {
  props: [
    // 'loc',
    'onLocChange'
  ],
  computed: {
    loc() {
      return this.$store.state.location
    },
    cart() {
      return this.$store.state.activeOrder
    },
    orderHandoff() {
      return this.$store.state.activeOrder ? this.$store.state.activeOrder.handoff : 'pickup'
    },
    user() {
      return this.$store.state.activeUser
    },
    // address() {
    //   if (this.cart.address) {
    //     return this.cart.address.address
    //   } else {
    //     return null
    //   }
    // },
    // city() {
    //   if (this.cart.address) {
    //     return this.cart.address.city
    //   } else {
    //     return null
    //   }
    // },
    // zip() {
    //   if (this.cart.address) {
    //     return this.cart.address.zip
    //   } else {
    //     return null
    //   }
    // },
    tempAddress() {
      return this.$store.state.orderAddress
    },
    formattedPhone() {
      return this.loc.phone.replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3')
    },
    openHours() {
      if(this.loc) {
        let day = moment().format('e')
        let open = this.loc.hours[day].open
        let close = this.loc.hours[day].close
        if (open == 'closed' || (this.loc.hours[day].holiday && this.loc.hours[day].holiday.closed == true)) {
          return 'Closed today'
        }
        if (open == close) {
          return 'Open 24 Hours'
        } else {
          if(close.match(/00$/)) {
            return `Open today til ${moment(close, 'H:mm').format('h A')}`
          } else {
            return `Open today til ${moment(close, 'H:mm').format('h:mm A')}`
          }
        }
        console.log(this.loc.content.hours, open, close)
      } else {
        return ''
      }
    },
    getDirections() {
      return encodeURI(`https://www.google.com/maps/place/${this.loc.address}, ${this.loc.city}, ${this.loc.state} ${this.loc.zip}`)
    },
    handoffText() {
      switch(this.orderHandoff) {
        case 'pickup':
          return 'Pickup'
          break
        case 'curbside':
          return 'Curbside'
          break
        default:
          return 'Delivery'
      }
    }
  },
  data() {
    return {
      menuHeight: 391,
      showInfo: false,
      scrollable: true,
      address: null,
      city: null,
      zip: null,
      deliveryMethod: 'Pickup',
      handoff: 'pickup',
      addressFormActive: false
    }
  },
  
  mounted() {
    if (this.cart && this.cart.address) {
      this.address = this.cart.address.address
      this.city = this.cart.address.city
      this.zip = this.cart.address.zip
    }
    window.addEventListener('resize', this.matchHeight)
    this.handoff = this.orderHandoff
    this.addressFormActive = this.cart && this.cart.address ? false : true
    this.matchHeight()
  },
  methods: {
    matchHeight() {
     setTimeout(function () {
        if (this.$refs.menuHeader) {
          let height = this.$refs.menuHeader.clientHeight
          this.menuHeight = height 
        }
      }.bind(this), 100)
    },
    async setHandoff(mode) {
      let order = null
      switch(mode) {
        case 'delivery':
        case 'dispatch':
          this.handoff = mode
          if(!this.cart) {
            order = await this.$api.createOrder(this.loc.menu_id)
          } else {
            order = JSON.parse(JSON.stringify(this.cart))
          }
          order.handoff = mode
          this.$store.commit('setActiveOrder', order)
          this.addressFormActive = true
          break
        default:
          this.$store.commit('setLoading', true)
          if(!this.cart) {
            order = await this.$api.createOrder(this.loc.menu_id)
            if(order) {
              this.$store.commit('setActiveOrder', order)
            }
          } else {
            order = JSON.parse(JSON.stringify(this.cart))
          }
          if(this.cart.handoff != mode) {
            order = await this.$api.setHandoff(this.cart.id, mode)
          }
          this.$store.commit('setLoading', false)
          if(order) {
            this.handoff = mode
            this.$store.commit('setActiveOrder', order)
          }
      }  
         
    },
    async addDeliveryAddress() {
      this.$store.commit('setLoading', true)
      let order = await this.$api.setAddress(this.cart.id, this.handoff, {
        address: this.address,
        city: this.city,
        zip: this.zip
      })
      this.$store.commit('setLoading', false)
      if(order) {
        this.$store.commit('setActiveOrder', order)
        if(order.address) this.addressFormActive = false
      }
    },
    async setDeliveryAddress(a) {
      this.$store.commit('setLoading', true)
      let order = await this.$api.setAddress(this.cart.id, this.handoff, a)
      this.$store.commit('setLoading', false)
      if(order) {
        this.$store.commit('setActiveOrder', order)
      }
    },
    changeLocation() {
      this.$store.commit('setLocationSelector', true)
    }
  }
}
