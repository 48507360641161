//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

 export default {
   props: [
    'content'
  ],
  data() {
    return {
      activeVid: 0
    }
  },
  mounted() {
    this.$nextTick(function () {
            this.activeVid = 0

      setTimeout(function () {
        this.loopVids()
      }.bind(this), 100)
    })
  },
  destroyed() {
  },
  methods: {
    scrubLink(link) {
      return link.replace('pages/', '')
    },
    loopVids() {
     const vids = document.querySelectorAll('video') 
     if (vids.length && window.innerWidth > 640) {
      this.activeVid = this.activeVid+1
       if (this.activeVid == 3) this.activeVid = 0
        vids[0].pause()
        vids[1].pause()
        vids[2].pause()
        let playPromise = vids[this.activeVid].play()
        if(playPromise !== undefined) {
          playPromise
            .then(() => {})
            .catch(err => {})
        }
      //  vids[this.activeVid].play()
        setTimeout(function () {
          this.loopVids()
        }.bind(this), 6000)
      }
     /*  const vids = document.querySelectorAll('video')
      if (vids.length && window.innerWidth > 640) {
        vids[0].pause()
        vids[1].pause()
        vids[2].pause()
        vids[this.activeVid].play()
        this.activeVid = this.activeVid+1
        if (this.activeVid == 3) {
          this.activeVid = 0
        }
        setTimeout(function () {
          this.loopVids()
        }.bind(this), 6000)
      } */
    }
  }
}
