//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    content: {
      type: Object,
      default: () => {}
    },
    featured:{
      type: Boolean,
      default: false
    },
  },
  data() {
    return {
      settings: {
        dots: false,
        adaptiveHeight: true,
        arrows: true,
        infinite: false,
        slidesToShow: 4,
        swipe: false,
        touchMove:false,
        swipeToSlide:false,
        responsive: [
          {
            breakpoint: 600,
            settings: {
              centerMode:true,
              centerPadding:'20%',
              slidesToShow:1,
              slidesToScroll: 1,
              initialSlide: 1,
              swipe: true,
              touchMove:true,
              swipeToSlide:true 
            }
          } 
        ]
      }
    }
  },
  mounted() {
    if (process.browser) window.addEventListener('resize', this.matchHeight)
    this.matchHeight()
  },
  destroyed() {
    if (process.browser) window.removeEventListener('resize', this.matchHeight)
  },
  methods: {
    scrubLink(link) {
      return link.replace('pages/', '')
    },
    showNext() {
      this.$refs.slider.next()
    },
    matchHeight() {
      if (process.browser) {
        const sliders = document.querySelectorAll('.menu-carousel')
        for (let i = sliders.length - 1; i >= 0; i--) {
          const cards = sliders[i].querySelectorAll('.card-body')
          let greatestHeight = 0
          let lastHeight = null
          for (var i2 = cards.length - 1; i2 >= 0; i2--) {
            cards[i2].style.height = 'auto'
          }
          for (var i2 = cards.length - 1; i2 >= 0; i2--) {
            lastHeight = cards[i2].offsetHeight
            if (lastHeight > greatestHeight) {
              greatestHeight = cards[i2].offsetHeight
            }
          }
          for (var i2 = cards.length - 1; i2 >= 0; i2--) {
            cards[i2].style.height = greatestHeight + 'px'
          }
        }
      }
    }
  }
}
