//
//
//
//
//

export default {
  props: [
    'content',
    'title'
  ],
  methods: {
    scrubLink(link) {
      return link.replace('pages/', '')
    },
    async handleLink(e, l) {
      if(l == '#order-pickup') {
        e.preventDefault()
        if(!this.$store.state.location) { // force location selection if no location
          this.$store.commit('setOrderType', 'pickup')
          this.$store.commit('setLocationSelector', true)
        } else if(!this.$store.state.location.menu_id) { // propmt that online ordering isn't available for the location
          this.$store.commit('setActiveErrors', ['Online ordering not available at this location'])
        } else if(!this.$store.state.activeOrder) { // create an order if none already created
          this.$store.commit('setLoading', true)
          let order = await this.$api.createOrder(this.$store.state.location.menu_id)
          this.$store.commit('setLoading', false)
          if(order) {
            this.$store.commit('setActiveOrder', order)
            this.$router.push('/order')
          }
        } else if(this.$store.state.activeOrder.handoff != 'pickup') { // set pickup if handoff is not pickup
          this.$store.commit('setLoading', true)
          let order = await this.$api.setHandoff(this.$store.state.activeOrder.id, 'pickup')
          this.$store.commit('setLoading', false)
          if(order) {
            this.$store.commit('setActiveOrder', order)
            this.$router.push('/order')
          }
        } else { // client already has an order with pickup as the handoff. go to order page. collect $200
          this.$router.push('/order')
        }
      } else if(l == '#order-delivery') {
        e.preventDefault()
        if(!this.$store.state.location) { // force location selection if no location
          this.$store.commit('setOrderType', 'delivery')
          this.$store.commit('setLocationSelector', true)
        } else if(!this.$store.state.location.menu_id) { // propmt that online ordering isn't available for the location
          this.$store.commit('setActiveErrors', ['Online ordering not available at this location'])
        } else if(!this.$store.state.activeOrder) { // create an order if none already created
          this.$store.commit('setLoading', true)
          let order = await this.$api.createOrder(this.$store.state.location.menu_id)
          this.$store.commit('setLoading', false)
          if(order) {
            if(this.$store.state.location.services.delivery) {
              order.handoff = 'delivery'
              this.$store.commit('setActiveOrder', order)
              this.$router.push('/order')
            } else if(this.$store.state.location.services.dispatch) {
              order.handoff = 'dispatch'
              this.$store.commit('setActiveOrder', order)
              this.$router.push('/order')
            } else {
              this.$store.commit('setActiveErrors', ['Delivery not available at this location'])
            }
          }
        } else if(this.$store.state.activeOrder.handoff != 'delivery' && this.$store.state.activeOrder.handoff != 'delivery') { // set pickup if handoff is not pickup
          let order = JSON.parse(JSON.stringify(this.$store.state.activeOrder))
          if(this.$store.state.location.services.delivery) {
            order.handoff = 'delivery'
            this.$store.commit('setActiveOrder', order)
            this.$router.push('/order')
          } else if(this.$store.state.location.services.dispatch) {
            order.handoff = 'dispatch'
            this.$store.commit('setActiveOrder', order)
            this.$router.push('/order')
          } else {
            this.$store.commit('setActiveErrors', ['Delivery not available at this location'])
          }
        } else { // client already has an order with pickup as the handoff. go to order page. collect $200
          this.$router.push('/order')
        }
      }
    }
  }
}
